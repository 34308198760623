/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { useMediaQuery } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import {
  TResource,
  TSequence,
  TTechniqueOption,
  EPagination,
} from '@/api/dbModels.types';
import {
  setError,
  setTechniqueLabels,
  setSelectedTechniqueLabels,
  setResources,
  setIsYTLoading,
} from '@/redux/Global.slice';
import { useFirestore } from '@/app/services/firebase/firebase.hooks';
import { capitalizeFirstLetter } from '@/common/services/utils';
import { useQueryAuth } from '@/api/firebase/authentication.hooks';
import { useNavigate } from 'react-router-dom';
import {
  doc,
  updateDoc,
  getFirestore,
  setDoc,
  arrayUnion,
} from 'firebase/firestore';

type TPlayerRef = { [key: string]: any };

export const useHomePage = () => {
  const {
    resources,
    totals,
    techniqueLabels,
    isYTLoading,
    selectedTechniqueLabels,
  } = useAppSelector((state) => state.globalSlice);

  const playerRefs = useRef<TPlayerRef>({}); // Map of video IDs to player instances
  const dispatch = useAppDispatch();
  const pendingPlayers = useRef(0); // To keep track of pending players
  const [isMobile] = useMediaQuery('(max-width: 400px)');
  const [appendedResources, setAppendedResources] = useState<TResource[]>([]); // State to hold appended resources for mobile
  const [limit, setLimit] = useState<number>(10);
  const [docs, setDocs] = useState<any[]>([]);
  const [previousDoc, setPreviousDoc] = useState<any>(null);
  const [page, setPage] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { firestore } = useFirestore();
  const { data: user } = useQueryAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const storedFbclid = localStorage.getItem('fbclid') || null; // Retrieve fbclid if stored

    // Run this on the main page to store fbclid in localStorage
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');

    if (storedFbclid && fbclid) {
      if (storedFbclid === fbclid) return;

      // Implement what to do when user clicks multiple times on ad
    }

    // Store fbclid if it exists
    if (fbclid) {
      localStorage.setItem('fbclid', fbclid);
    }
  }, []);

  const fetchAggregates = async () => {
    try {
      // Reference to the aggregates document
      const aggregatesRef = firestore.collection('aggregates').doc('sequences');
      const doc = await aggregatesRef.get();

      if (doc.exists) {
        const data = doc.data();
        if (!data) return;

        const sequences = data.sequences || [];

        // Helper function to format array items to { label, value } format with capitalized label
        const formatOptions = (arr: string[]) => {
          return arr.map((item) => ({
            label: item.charAt(0).toUpperCase() + item.slice(1),
            value: item,
          }));
        };

        dispatch(setTechniqueLabels([...formatOptions(sequences)]));
      } else {
        console.error('No aggregate documents');
      }
    } catch (error) {
      console.error('Error fetching aggregates: ', error);
    }
  };

  /**
   * Loads the YouTube IFrame API script dynamically.
   *
   * Inserts the YouTube IFrame API <script> tag into the document, ensuring
   * the API is available for creating YouTube players. The script is inserted
   * before the first existing script tag to prioritize loading.
   */
  const loadYouTubeIframeAPI = () => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag && firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  };

  /**
   * Jumps the specified YouTube player to a given timestamp.
   *
   * Locates the YouTube player by its resource ID and uses the seekTo function
   * to skip to the specified timestamp. Ensures the player is ready before
   * attempting to seek.
   *
   * @param {string} resourceId - The ID of the video player to control.
   * @param {number} timestamp - The time (in seconds) to jump to in the video.
   */
  const jumpToTimestamp = (resourceId: string, timestamp: number) => {
    const player = playerRefs.current[resourceId];

    if (player && typeof player.seekTo === 'function') {
      player.seekTo(timestamp, true);
    }
  };

  /**
   * Initializes YouTube players for each resource.
   *
   * This function iterates over the resources array and creates a YouTube player
   * instance for each video using the YouTube IFrame API. Each player is stored
   * in the playerRefs.current object for later access and control.
   *
   * @param {Array} resources - Array of resources containing video IDs and YouTube IDs.
   * @param {Object} playerRefs - Reference object to store initialized players.
   */
  const initializeYouTubePlayers = (
    resource: TResource,
    _resourcesLength: number
  ) => {
    if (!window.YT) {
      console.error('YouTube IFrame API not loaded');
      return;
    }

    const { id, youtubeId } = resource;

    playerRefs.current[id] = new window.YT.Player(id, {
      height: '390',
      width: '100%',
      videoId: youtubeId,
      playerVars: {
        controls: 1,
      },
      events: {
        onReady: (event: YT.PlayerEvent) => {
          playerRefs.current[id] = event.target;

          handleLoading(_resourcesLength);
        },
        onError: (error: any) => {
          console.error(`Failed to initialize player ${id}:`, error);
        },
      },
    });
  };

  /**
   * Sets up the YouTube IFrame API and initializes video players.
   *
   * This effect runs once when the component mounts. It defines a global handler
   * (`window.onYouTubeIframeAPIReady`) that initializes YouTube players for each
   * resource as soon as the YouTube IFrame API is loaded. The `loadYouTubeIframeAPI`
   * function dynamically loads the API script, triggering the initialization process.
   */
  useEffect(() => {
    window.onYouTubeIframeAPIReady = () => {
      // Call the separate function to initialize the players
      resources.map((res) => initializeYouTubePlayers(res, resources.length));
    };

    loadYouTubeIframeAPI();

    fetchAggregates();

    return () => {
      playerRefs.current = [];
      dispatch(setSelectedTechniqueLabels([]));
      dispatch(setResources([]));
      setAppendedResources([]);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      // Initialize only the last 10 resources if the device is mobile
      const lastTenResources = resources.slice(-10);
      lastTenResources.forEach((res) =>
        initializeYouTubePlayers(res, resources.length)
      );
    } else {
      resources.map((res) => initializeYouTubePlayers(res, resources.length));
    }
  }, [resources]);

  /**
   * Handles the loading state of YouTube players.
   *
   * This function increments the count of initialized players (`pendingPlayers.current`).
   * Once the number of initialized players matches the total number of resources,
   * it dispatches an action to stop the loading state by setting `setIsYTLoading(false)`.
   * Finally, it resets `pendingPlayers.current` to 0.
   *
   * Note: If using a "load more" functionality to load additional players,
   * ensure to set `pendingPlayers.current` to `resources.length` before starting the
   * loading process. This ensures that the counter reflects the total number of
   * players expected to load, preventing premature stopping of the loading state.
   */
  const handleLoading = (_resourcesLength: number) => {
    pendingPlayers.current += 1;

    if (pendingPlayers.current === _resourcesLength) {
      // All players are loaded
      dispatch(setIsYTLoading(false));
    }
  };

  const createName = ({
    startingPosition,
    sequence: seq,
  }: TSequence): string => {
    // Convert both startingPosition and seq to lowercase arrays of words
    const startingWords = startingPosition.toLowerCase().split(' ');
    const sequenceWords = seq.toLowerCase().split(' ');

    // Filter out words in seq that are also in startingPosition
    const filteredSequenceWords = sequenceWords.filter(
      (word: string) => !startingWords.includes(word)
    );

    // Reconstruct the filtered sequence string and capitalize it
    const filteredSequence = filteredSequenceWords
      .map((word: string) => capitalizeFirstLetter(word))
      .join(' ');

    // Construct the name with the filtered sequence
    const name = `${capitalizeFirstLetter(
      startingPosition
    )} ${String.fromCharCode(8212)}> ${filteredSequence}`;

    return name;
  };

  const createResourcesFromSequences = (_sequences: TSequence[]) => {
    const newResources = _sequences.reduce<TResource[]>((acc, sequence) => {
      const { youtubeId } = sequence;
      const name = createName(sequence);

      const resource: TResource = {
        id: `${sequence._id}-${sequence.sequenceNumber} `,
        youtubeId,
        display: [
          {
            id: `${youtubeId}_${name}`,
            name,
            sequences: [sequence],
          },
        ],
      };
      acc.push(resource);

      return acc;
    }, []);

    return newResources;
  };

  const fetchSequences = async (
    labels: TTechniqueOption[],
    _limit: number,
    startAfter?: any
  ) => {
    try {
      const results = await Promise.all(
        labels.map(async (stl) => {
          let sequenceQuery = firestore
            .collection('sequences')
            .where('sequence', '==', stl.value)
            .orderBy('sequenceNumber')
            .limit(_limit);

          if (startAfter) {
            sequenceQuery = sequenceQuery.startAfter(startAfter);
          }

          const snapshot = await sequenceQuery.get();

          setDocs(snapshot.docs);

          const sequences = snapshot.docs.map((doc) => doc.data());

          setLimit(sequences.length);
          return sequences;
        })
      );
      // Flatten the results and filter out empty arrays
      const data = results.flat().filter((seq) => seq);
      return data as TSequence[];
    } catch (error) {
      console.error('Error in fetching sequences:', error);
      dispatch(setError(`Error: ${error}`));
    }
  };

  const getSequences = async (
    _sequences: TTechniqueOption[],
    pagination: EPagination
  ) => {
    dispatch(setIsYTLoading(true));

    if (isMobile) {
      pendingPlayers.current = resources.length; // track loading
    } else {
      pendingPlayers.current = 0; // track loading
    }

    let newSequences: TSequence[] | undefined = [];

    if (pagination === EPagination.Initial || pagination === EPagination.New) {
      setPreviousDoc(null);
      setDocs([]);
      setPage(1);
      pendingPlayers.current = 0; // track loading
      newSequences = await fetchSequences(_sequences, 10, null);
    }

    if (pagination === EPagination.Previous) {
      if (page === 2) {
        setPreviousDoc(null);
      }
      setPage((prev) => prev - 1);
      newSequences = await fetchSequences(_sequences, limit, previousDoc);
    }

    if (pagination === EPagination.Next) {
      setPreviousDoc(docs[0]);
      setPage((prev) => prev + 1);
      newSequences = await fetchSequences(
        _sequences,
        limit,
        docs[docs.length - 1]
      );
    }

    if (!newSequences) {
      console.error('Error: new sequences undefined');
      return;
    }

    const newResources = createResourcesFromSequences(newSequences);

    if (pagination === EPagination.New && isMobile) {
      dispatch(setResources(newResources));
      setAppendedResources([]);
      return;
    }

    if (isMobile) {
      // On mobile, append new resources to the existing resources
      const combinedResources = appendedResources.concat(newResources); // Append new resources to existing ones
      setAppendedResources(combinedResources); // Update appended resources state
      dispatch(setResources(combinedResources)); // Update resources in the store with combined data
    } else {
      // On desktop, simply replace the resources
      dispatch(setResources(newResources));
    }
  };

  const onSequenceSelect = (selectedSequence: TTechniqueOption[]) => {
    console.log(
      '🚀 ~ onSequenceSelect ~ user?.searchCount:',
      user?.searchCount,
      user
    );
    if (!user) {
      navigate('/signup');
      return;
    }
    if (!user?.isPaidSubscriber && user?.searchCount === 2) {
      setIsModalOpen(true);
      return;
    }

    if (!selectedSequence.length) {
      playerRefs.current = [];
      dispatch(setResources([]));
      setAppendedResources([]);
    }

    if (selectedSequence[0] !== selectedTechniqueLabels[0]) {
      playerRefs.current = [];
      getSequences(selectedSequence, EPagination.New);
    } else {
      getSequences(selectedSequence, EPagination.Initial);
    }

    dispatch(setSelectedTechniqueLabels(selectedSequence));

    logSearch(selectedSequence[0]);

    increaseUserSearchCount();
  };

  const logSearch = async (sequence: TTechniqueOption) => {
    if (!user) {
      return;
    }
    const db = getFirestore();

    const userDocRef = doc(db, 'users', user.uid);

    // Update the searchTerms array by adding the new sequence
    await updateDoc(userDocRef, {
      searchTerms: arrayUnion(sequence),
    }).catch(async (error) => {
      // If the document doesn't exist, create it with the array
      if (error.code === 'not-found') {
        await setDoc(userDocRef, {
          searchTerms: [sequence],
        });
      } else {
        console.error('Error updating search terms: ', error);
      }
    });
  };

  const increaseUserSearchCount = async () => {
    try {
      const userDocRef = firestore.collection('users').doc(user?.uid);
      await userDocRef
        .update({
          searchCount: (user?.searchCount || 0) + 1,
        })
        .then(() => {
          console.log(
            `Search count updated successfully!: ${
              (user?.searchCount || 0) + 1
            }`
          );
        });
    } catch (error) {
      console.error('Error updating search count: ', error);
    }
  };

  const shouldDisableNextFetch = limit < 10;

  const shouldDisablePreviousFetch = !previousDoc;

  return {
    resources,
    totals,
    isYTLoading,
    shouldDisableNextFetch,
    shouldDisablePreviousFetch,
    getSequences,
    jumpToTimestamp,
    onSequenceSelect,
    selectedTechniqueLabels,
    techniqueOptions: techniqueLabels,
    isModalOpen,
    setIsModalOpen,
  };
};
